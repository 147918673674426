<template>
  <div :class="['entity-editform', { 'is-approved': isApproved, 'is-editmode': editMode, 'is-noedit': noEdit, 'is-disabled': isDisabled }]">
    <div v-if="isBusy || is.loading || validatorsIs.loading" class="form-busy">
      <c-loader inline scale="2.5"/>
    </div>

    <template v-else>
      <div class="form-data">
        <b-row>
          <template v-for="property in properties">
            <b-col v-if="(property._table || {}).title" :key="`${property.name}_title`" cols="12" class="group-title">
              <h4>{{ property._table.title }}</h4>
            </b-col>

            <template v-if="property.visible">
              <b-col :key="`${property.control.id}_label`" :[$root.mobile.up.key]="4" class="data-col-label">
                <label :for="property.name">{{ property.control.label }}</label>
              </b-col>

              <b-col :key="`${property.control.id}_value`" :[$root.mobile.up.key]="8" class="data-col-value">
                <div v-if="!editMode || property.control.readonly" :class="{ 'form-control-plaintext': editMode && property.control.readonly }">
                  <div v-html="valueFormatter(definition, property.name, property.control.value).html"/>
                </div>

                <component
                  v-else
                  :is="property.control.is"
                  v-model="property.control.value"
                  v-bind="property.control"
                  labelHide

                  @change="property.change"
                />

                <div v-if="editMode && property.changeRequests.length > 0" class="data-changerequests">
                  <div v-for="changeRequest in property.changeRequests" :key="changeRequest.id">
                    <div>{{ $T('HasChangeRequest') }}</div>
                    <b-icon icon="b-arrow-right"/> {{ changeRequest.value }}
                  </div>
                </div>
              </b-col>
            </template>
          </template>
        </b-row>
      </div>

      <div v-if="!noEdit && !isDisabled" class="form-action">
        <b-row align-v="center" align-h="between">
          <b-col cols="auto"></b-col>

          <b-col cols="auto">
            <template v-if="editMode">
              <div class="btn-list">
                <b-button variant="outline-secondary" size="sm" @click="abort()"><b-icon icon="abort"/> {{ $T('abortbutton') }}</b-button>
                <b-button variant="primary" size="sm" @click="update()"><b-icon icon="save"/> {{ $T('savebutton') }}</b-button>
              </div>
            </template>

            <template v-else>
              <b-button variant="link" size="sm" class="px-0" @click="toggle()"><b-icon icon="edit"/> {{ $T('editbutton') }}</b-button>
            </template>
          </b-col>
        </b-row>
      </div>
    </template>

    <div v-if="!isApproved" class="form-info">
      <b-badge variant="dark">{{ $T('NotApproved') }}</b-badge>
    </div>
  </div>
</template>

<script>
import { CHANGEREQUEST_STATES } from '@/assets/js/config/server'

import { unwrap, valueFormatter, propertyMapper } from '@/assets/js/helper/entity'

const ALLOWED_CHANGEREQUEST_STATES = [CHANGEREQUEST_STATES.open]

export default {
  name: 'Entity.EditForm',
  props: {
    isBusy: {
      type: Boolean,
      default: false
    },
    entityKey: {
      type: String,
      required: true
    },
    entityId: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    noEdit: {
      type: Boolean,
      default: false
    },
    definitionModifier: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      editMode: this.$props.edit,
      controls: {}
    }
  },
  validations () {
    return {
      controls: Object.keys(this.controls)
        .reduce((controls, cKey) => Object.assign(controls, { [cKey]: { value: this.controls[cKey].validations } }), {})
    }
  },
  computed: {
    storeKey () {
      return this.entityKey.toLowerCase()
    },
    validatorsIs () {
      return this.$store.getters['validators/is']
    },
    validators () {
      return this.$store.getters['validators/get']
    },
    is () {
      return this.$store.getters[`${this.storeKey}/is`]
    },
    definition () {
      return typeof this.definitionModifier === 'function' ? this.definitionModifier(this.$store.getters[`${this.storeKey}/definition`]) : this.$store.getters[`${this.storeKey}/definition`]
    },
    entity () {
      return this.$store.getters[`${this.storeKey}/getEntity`](this.entityId) || {}
    },
    properties () {
      return propertyMapper(this.definition, this.$store.getters['gui/getLanguage'], this.validators, this.entity, ALLOWED_CHANGEREQUEST_STATES)
    },
    isApproved () {
      const entity = unwrap(this.entity)
      return entity.isApproved !== undefined ? entity.isApproved : true
    },
    isDisabled () {
      return !this.isApproved
    }
  },
  methods: {
    valueFormatter,
    registerControl (control) {
      if (this.$el.querySelector(`#${control.id}`)) {
        this.controls[control.id] = control
      }
    },
    toggle (state = null) {
      this.controls = {}
      this.editMode = state !== null ? state : !this.editMode
    },
    touch () {
      Object.values(this.controls).forEach(control => { control.$touch() })
      this.$v.$touch()
    },
    abort () {
      this.properties.forEach(p => p.reset())
      Object.values(this.controls).forEach(c => c.$reset())
      this.toggle(false)
    },
    update () {
      if (this.$v.$invalid) {
        this.touch()
      } else {
        this.$emit('entity:update', Object.assign({}, this.entity.value, this.properties.reduce((properties, p) => Object.assign(properties, { [p.name]: p.control.value }), {})))
        this.properties.forEach(p => p.update())
        this.toggle(false)
      }
    }
  },
  created () {
    this.$store.dispatch('validators/get')
    this.$root.$on('control:ready', this.registerControl)
  }
}
</script>

<style lang="scss">
$entity-editform-padding-y: $component-padding-y * 0.5 !default;
$entity-editform-padding-x: $component-padding-x * 0.5 !default;
$entity-editform-border: $border-width $border-style $border-color !default;

$entity-editform-group-title-gap: $spacer * 1.25 !default;
$entity-editform-group-title-margin-bottom: $entity-editform-group-title-gap * 0.75 !default;
$entity-editform-group-title-padding: ($spacer * 0.1) 0 !default;
$entity-editform-group-title-font-size: $h5-font-size !default;
$entity-editform-group-title-font-weight: $font-weight-bold !default;
$entity-editform-group-title-color: $secondary !default;

$entity-editform-col-label-gap: 0 !default;

$entity-editform-label-font-size: $font-size-sm !default;
$entity-editform-label-font-weight: $font-weight-bold !default;
$entity-editform-label-color: inherit !default;

$entity-editform-col-value-gap: $entity-editform-col-label-gap !default;

$entity-editform-value-font-size: $font-size-base !default;
$entity-editform-value-font-weight: inherit !default;
$entity-editform-value-color: inherit !default;

$entity-editform-changerequests-border-width: $input-border-width !default;
$entity-editform-changerequests-border-color: $info !default;
$entity-editform-changerequests-font-size: $font-size-sm * 0.85 !default;
$entity-editform-changerequests-color: $entity-editform-changerequests-border-color !default;

$entity-editform-action-gap: $spacer * 0.5 !default;

$entity-editform-disabled-bg: $gray-100 !default;
$entity-editform-disabled-color: inherit !default;

$entity-editform-mobile-breakpoint: $mobile-breakpoint !default;

$entity-editform-mobile-col-label-gap: $spacer * 0.5 !default;
$entity-editform-mobile-col-value-gap: 0 !default;

.entity-editform {
  position: relative;
  padding: $entity-editform-padding-y $entity-editform-padding-x;

  &:first-child {
    margin-top: $entity-editform-padding-y * -1;
  }

  + .entity-editform {
    padding: $entity-editform-padding-y $entity-editform-padding-x;
    border-top: $entity-editform-border;
  }

  .form-data {
    .group-title {
      > * {
        margin-top: $entity-editform-group-title-gap;
        margin-bottom: $entity-editform-group-title-margin-bottom;
        padding: $entity-editform-group-title-padding;
        font-size: $entity-editform-group-title-font-size;
        font-weight: $entity-editform-group-title-font-weight;
        color: $entity-editform-group-title-color;
      }

      &:first-child {
        > * {
          margin-top: 0;
        }
      }
    }

    .form-group {
      margin-bottom: 0;

      .input-group {
        margin-bottom: 0!important;
      }
    }

    .data-col-label {
      display: flex;
      align-items: center;
      margin-top: $entity-editform-col-label-gap;

      label {
        display: block;
        margin: 0;
        font-size: $entity-editform-label-font-size;
        font-weight: $entity-editform-label-font-weight;
        color: $entity-editform-label-color;
      }

      &:first-child {
        margin-top: 0;

        + .data-col-value {
          margin-top: 0;
        }
      }
    }

    .data-col-value {
      margin-top: $entity-editform-col-value-gap;
      font-size: $entity-editform-value-font-size;
      font-weight: $entity-editform-value-font-weight;
      color: $entity-editform-value-color;

      > * {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
      }

      .control-checkbox,
      .control-radio {
        padding-top: $input-padding-y;
      }

      .data-changerequests {
        position: relative;
        margin-top: $entity-editform-changerequests-border-width * -1;
        border-top: $entity-editform-changerequests-border-width solid $entity-editform-changerequests-border-color;
        font-size: $entity-editform-changerequests-font-size;
        color: $entity-editform-changerequests-color;
        z-index: 3;
      }

      .is-valid,
      .is-invalid {
        + .data-changerequests {
          border-top-color: transparent;
        }
      }
    }
  }

  .form-action {
    margin: $entity-editform-action-gap ($entity-editform-padding-x * -1) 0;
  }

  .form-info {
    position: absolute;
    bottom: $entity-editform-padding-y;
    right: $entity-editform-padding-x;
  }

  &.is-approved {}

  &.is-editmode {
    .entityform-data {
      .data-col-label {
        label {
          padding-top: $input-padding-y;

          &.label-sm {
            padding-top: $input-padding-y-sm;
          }

          &.label-lg {
            padding-top: $input-padding-y-lg;
          }
        }
      }
    }
  }

  &.is-disabled {
    margin-top: 0;
    background-color: $entity-editform-disabled-bg;
    color: $entity-editform-disabled-color;
  }

  @include media-breakpoint-down($entity-editform-mobile-breakpoint) {
    .form-data {
      .data-col-label {
        margin-top: $entity-editform-mobile-col-label-gap;

        &:first-child {
          margin-top: 0;

          + .data-col-value {
            margin-top: 0;
          }
        }
      }

      .data-col-value {
        margin-top: $entity-editform-mobile-col-value-gap;
      }
    }
  }
}
</style>
