<template>
  <b-form :id="id" :class="['formluar', { 'is-inline': inline, 'max-width': maxWidth }]" novalidate @submit="onSubmit">
    <div v-if="isBusy" class="form-busy">
      <c-loader :type="loaderType"/>
    </div>

      <b-form-row v-else :class="{ [`align-items-${inlineAlignment}`]: inline }">
        <b-col :cols="inline ? 'auto' : 12">
          <b-form-row class="form-controls">
            <b-col v-for="(control, cKey) in form.controls" :key="cKey" :sm="inline ? 'auto' : control._grid.cols">
              <component
                v-if="control.visible"
                :is="control.control.is"
                :class="{ 'mb-0': inline }"
                v-model="control.control.value"
                v-bind="control.control"
                @input="onChange(control)"
                @change="onChange(control)"
              />
            </b-col>
          </b-form-row>
        </b-col>

        <b-col v-if="showButtons" :cols="inline ? 'auto' : 12">
          <div class="form-actions">
            <div :class="`justify-content-${buttonAlignment}`">
              <b-button v-if="showAbortButton" :variant="form.actions.abort.variant" :size="form.actions.abort.size" @click="onAbort"><slot name="abort"><b-icon icon="abort"/> {{ $T('AbortButton') }}</slot></b-button>
              <b-button v-if="showResetButton" :variant="form.actions.reset.variant" :size="form.actions.reset.size" @click="onReset"><slot name="reset"><b-icon icon="reset"/> {{ $T('ResetButton') }}</slot></b-button>
              <b-button :variant="form.actions.submit.variant" :size="form.actions.submit.size" @click="onSubmit"><slot name="submit"><b-icon icon="send"/> {{ $T('SubmitButton') }}</slot></b-button>
            </div>
          </div>
        </b-col>
      </b-form-row>
  </b-form>
</template>

<script>
import { controlMapper } from '@/assets/js/helper/entity'

export default {
  name: 'Formular',
  props: {
    isBusy: {
      type: Boolean,
      default: false
    },
    loaderType: {
      type: String,
      // visit @/components/Loader.vue for more informations
      default: 'default'
    },
    id: {
      type: String,
      default: 'Formular'
    },
    controlDefinition: {
      type: Object,
      required: true,
      default: () => ({})
    },
    controlValues: {
      type: Object,
      default: () => ({})
    },
    triggerInit: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    inlineAlignment: {
      type: String,
      default: 'end',
      validator: value => ['start', 'center', 'end'].includes(value)
    },
    maxWidth: {
      type: Boolean,
      default: false
    },
    labelCols: {
      type: Number,
      default: null
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    showResetButton: {
      type: Boolean,
      default: false
    },
    showAbortButton: {
      type: Boolean,
      default: false
    },
    buttonAlignment: {
      type: String,
      default: 'end',
      validator: value => ['start', 'center', 'end'].includes(value)
    },
    buttonVariants: {
      type: Object,
      default: () => ({})
    },
    buttonSizes: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        controls: {},
        actions: {
          submit: {
            variant: this.$props.buttonVariants.submit || 'primary',
            size: this.$props.buttonSizes.submit || 'md'
          },
          reset: {
            variant: this.$props.buttonVariants.reset || 'secondary',
            size: this.$props.buttonSizes.reset || 'md'
          },
          abort: {
            variant: this.$props.buttonVariants.abort || 'secondary',
            size: this.$props.buttonSizes.abort || 'md'
          }
        }
      }
    }
  },
  validations () {
    return {
      form: {
        controls: Object.keys(this.form.controls)
          .filter(cKey => this.form.controls[cKey].visible)
          .reduce((controls, cKey) => Object.assign(controls, { [cKey]: { control: { value: this.form.controls[cKey].control.validations } } }), {})
      }
    }
  },
  computed: {
    validators () {
      return this.$store.getters['validators/get']
    },
    formData () {
      return {
        id: this.id,
        controls: Object.keys(this.form.controls)
          .filter(cKey => !this.form.controls[cKey].control.readonly)
          .reduce((formData, cKey) => Object.assign(formData, { [cKey]: this.form.controls[cKey].control.value }), {}),
        isValid: !this.$v.$invalid,
        isDirty: !this.$v.$dirty
      }
    }
  },
  methods: {
    createControls () {
      this.form.controls = controlMapper(this.controlDefinition, this.$store.getters['gui/getLanguage'], this.validators, this.controlValues)
      this.onInit()
      if (this.triggerInit) this.onChange()
    },
    touchControls () {
      Object.keys(this.form.controls)
        .forEach(cKey => {
          this.form.controls[cKey].change()
        })

      this.$v.$touch()
      this.$emit('formular:touch', !this.$v.$invalid)
    },
    resetControls () {
      Object.keys(this.form.controls)
        .forEach(cKey => {
          this.form.controls[cKey].reset()
        })

      this.$v.$reset()
    },
    onInit () {
      this.$emit('formular:init', this.formData)
    },
    onChange (control = null) {
      if (control) control.change()
      this.$emit('formular:change', this.formData)
    },
    onSubmit () {
      if (this.$v.$invalid) {
        this.touchControls()
        this.$emit('formular:invalidsubmit', this.formData)
      } else {
        this.$emit('formular:submit', this.formData)
        this.resetControls()
      }
    },
    onReset () {
      this.resetControls()
      this.$emit('formular:reset')
    },
    onAbort () {
      this.resetControls()
      this.$emit('formular:abort')
    }
  },
  created () {
    this.$root.$on(`${this.id}:validate`, this.touchControls)

    this.createControls()
    this.$store.dispatch('validators/get')
  },
  watch: {
    validators () {
      this.createControls()
    },
    '$props.controlDefinition' () {
      this.createControls()
    },
    '$props.controlValues' () {
      this.createControls()
    },
    // this fixes a strange error, where this.$v is null, after reinitializing the component and adding removing the content of a control which is required
    $v () {}
  }
}
</script>

<style lang="scss">
$formular-max-width: 550px !default;

$formular-actions-gap: $spacer !default;
$formular-actions-button-gap: $spacer * 0.125 !default;

$formular-controls-input-inline-min-width: auto !default;
$formular-controls-textarea-inline-min-width: 280px !default;
$formular-controls-select-inline-min-width: auto !default;

.formluar {
  .form-busy {}

  .form-controls {}

  .form-actions {
    margin-top: $formular-actions-gap;

    > div {
      display: flex;
      align-items: center;
      margin: $formular-actions-button-gap * - 1;

      .btn {
        margin: $formular-actions-button-gap;
      }
    }
  }

  &.is-inline {
    max-width: 100%;

    .form-controls {
      .control-input {
        min-width: $formular-controls-input-inline-min-width;
      }

      .control-textarea {
        min-width: $formular-controls-textarea-inline-min-width;
      }

      .control-select {
        min-width: $formular-controls-select-inline-min-width;
      }
    }

    .form-actions {
      margin-top: 0;
    }
  }

  &.max-width {
    max-width: $formular-max-width;
  }
}
</style>
