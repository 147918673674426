<template>
  <div class="entitychangerequeststable">
    <div class="entitychangerequeststable-header">
      <control-checkbox
        :id="table.filter.id"
        v-model="table.filter.value"
        :options="table.filter.options"
      />
    </div>

    <b-table
      :fields="changeRequestsTable.fields"
      :items="changeRequestsTable.items"
      :filter="table.filter.value[0]"
      :filterOn="table.filter.on"
      thead-class="d-none"
      borderless
      small
    >
      <template #cell($current)="data">
        <span v-html="valueFormatter(definitions[data.item.$entityKey], data.item.propertyName, data.item.originalValue || data.value).html"/> <b-icon icon="b-arrow-right" class="d-block d-md-inline-block my-1 my-md-0"/> <span v-html="valueFormatter(definitions[data.item.$entityKey], data.item.propertyName, data.item.value).html"/>
      </template>

      <template #cell(status)="data">
        <b-badge :variant="table.options.items.status.getVariant(data.item)" :class="{ 'badge-outline': table.options.items.status.getOutline(data.item) }" pill>{{ $T(`state${data.value}`) }}</b-badge>
      </template>

      <template #cell(updated)="data">
        <timeago :datetime="data.value || data.item.created" :converterOptions="{ forceTextFormat: true }" auto-update/>
      </template>

      <template #cell($kill)="data">
        <b-button v-if="data.value" variant="link" class="p-0 text-danger" @click="openKillConfirmationModal(data.item)"><b-icon icon="close"/></b-button>
      </template>
    </b-table>

    <b-modal
      :id="killConfirmationModal.id"
      v-model="killConfirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-class="align-items-center"
      header-bg-variant="warning"
      header-text-variant="dark"
      title-class="h3"
    >
      <template #modal-header-close><b-icon icon="close" scale="0.75"/></template>
      <template #modal-title>{{ $T('killconfirmationtitle') }}</template>

      <div v-html="$T('killconfirmationcontent')"/>

      <template #modal-footer>
        <b-button variant="light" size="sm" @click="closeKillConfirmationModal">{{ $T('killconfirmationabortbutton') }}</b-button>
        <b-button variant="danger" size="sm" @click="killChangeRequest">{{ $T('killconfirmationconfirmbutton') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { CHANGEREQUEST_STATES } from '@/assets/js/config/server'
import { CHANGEREQUEST_STATEBADGE_OUTLINE } from '@/assets/js/config/client'

import Table from '@/assets/js/helper/table'
import { valueFormatter } from '@/assets/js/helper/entity'

const CHANGEREQUEST_STATES_KILLABLE = [CHANGEREQUEST_STATES.open]

export default {
  name: 'Entity.ChangerequestsTable',
  props: {
    definitions: {
      type: Object,
      default: () => ({})
    },
    entities: {
      type: Array,
      default: () => ([])
    },
    noEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      table: {
        filter: {
          id: `${this.$options.name}_Table_Filter`,
          value: [CHANGEREQUEST_STATES.open],
          options: [{ text: this.$T('filter'), value: CHANGEREQUEST_STATES.open }],
          on: ['status']
        },
        options: {
          fields: {
            includes: ['$name', '$current'].concat(this.noEdit ? [] : '$kill'),
            excludes: [],
            sorting: ['$name', '$current', 'status', 'updated'].concat(this.noEdit ? [] : '$kill'),
            sortable: [],
            labelKey: '',
            label: {},
            variant: {},
            class: {},
            thClass: {},
            tdClass: {
              $kill: 'cell-min'
            }
          },
          items: {
            status: {
              getVariant: data => Object.keys(CHANGEREQUEST_STATES).find(sKey => CHANGEREQUEST_STATES[sKey] === data.status),
              getOutline: data => CHANGEREQUEST_STATEBADGE_OUTLINE[Object.keys(CHANGEREQUEST_STATES).find(sKey => CHANGEREQUEST_STATES[sKey] === data.status)]
            }
          }
        }
      },
      killConfirmationModal: {
        id: `${this.$options.name}_KillConfirmationModal`,
        show: false,
        item: null
      }
    }
  },
  computed: {
    changeRequests () {
      return this.entities
        .map(e => e.changeRequests
          .map(cr => {
            return Object.assign({}, cr, {
              $entityKey: e.key,
              $entityId: e.value.id,
              $name: this.definitions[e.key].$properties[cr.propertyName].translations[this.$store.getters['gui/getLanguage']].name,
              $current: e.value[cr.propertyName],
              $kill: CHANGEREQUEST_STATES_KILLABLE.includes(cr.status),
              _rowVariant: CHANGEREQUEST_STATES_KILLABLE.includes(cr.status) ? 'history-start' : ''
            })
          })
        )
        .flat()
        .sort((crA, crB) => {
          const aConstraintKey = `${crA.$entityId}_${crA.propertyName}_${9999999999999 - new Date(crA.updated || crA.created).getTime()}`
          const bConstraintKey = `${crB.$entityId}_${crB.propertyName}_${9999999999999 - new Date(crB.updated || crB.created).getTime()}`

          return aConstraintKey < bConstraintKey ? -1 : aConstraintKey > bConstraintKey ? 1 : 0
        })
    },
    changeRequestsTable () {
      return new Table(this.changeRequests, this.table.options.fields)
    }
  },
  methods: {
    valueFormatter,
    openKillConfirmationModal (item) {
      this.killConfirmationModal.item = item
      this.killConfirmationModal.show = true
    },
    closeKillConfirmationModal () {
      this.killConfirmationModal.show = false
      this.killConfirmationModal.item = null
    },
    killChangeRequest () {
      this.$store.dispatch(`${this.killConfirmationModal.item.$entityKey}/removeChangeRequest`, this.killConfirmationModal.item.id)
      this.closeKillConfirmationModal()
    }
  }
}
</script>

<style lang="scss">
$entitychangerequeststable-header-gap: $spacer !default;

$entitychangerequeststable-history-start-border: $border-width $border-style $border-color !default;

.entitychangerequeststable {
  .entitychangerequeststable-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $entitychangerequeststable-header-gap;
  }

  table {
    tr {
      &.table-history-start {
        border-top: $entitychangerequeststable-history-start-border;

        &:first-child {
          border-top: 0 none;
        }
      }
    }
  }
}
</style>
