<template>
  <div>
    <template v-if="!editMode">
      <div class="d-flex justify-content-end">
        <b-button variant="link" size="sm" class="px-0" @click="toggleMode(true)"><b-icon icon="plus"/> {{ $T('addButton') }}</b-button>
      </div>
    </template>

    <template v-else>
      <formular
        id="Form.AddEntity"
        :isBusy="is.loading"
        :controlDefinition="definition"
        showAbortButton
        :buttonSizes="{ submit: 'sm', abort: 'sm' }"
        @formular:abort="toggleMode(false)"
        @formular:submit="onSubmit"
      >
        <template #abort><b-icon icon="abort"/> {{ $T('abortButton') }}</template>
        <template #submit><b-icon icon="plus"/> {{ $T('submitButton') }}</template>
      </formular>
    </template>
  </div>
</template>

<script>
import Formular from '@/components/form/Formular'

export default {
  name: 'Entity.AddForm',
  components: {
    Formular
  },
  props: {
    entityKey: {
      type: String,
      required: true
    },
    parentId: {
      type: String,
      required: true
    },
    parentIdKey: {
      type: String,
      required: true
    },
    initialValues: {
      type: Object,
      default: () => ({})
    },
    definitionModifier: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      editMode: false
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    definition () {
      return typeof this.definitionModifier === 'function' ? this.definitionModifier(this.$store.getters[`${this.entityKey}/definition`]) : this.$store.getters[`${this.entityKey}/definition`]
    }
  },
  methods: {
    toggleMode (state = null) {
      this.editMode = state !== null ? state : !this.editMode
    },
    onSubmit (formData) {
      this.$store.dispatch(`${this.entityKey}/createEntity`, Object.assign({ [this.parentIdKey]: this.parentId }, formData.controls))
        .finally(() => {
          this.toggleMode(false)
        })
    }
  }
}
</script>

<style lang="scss"></style>
